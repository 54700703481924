import {
    Form,
    Radio,
    Input,
    Button,
    Card,
    Modal,
    Select,
    DatePicker,
    Checkbox,
    message,
    Upload,
    InputNumber
} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './../voucher.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import {UploadOutlined} from "@ant-design/icons";
import CircularProgress from "../../../../components/CircularProgress";


const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const SingleVoucherEditV2 = (props) => {

    // const formRef = React.createRef();
    const [beneficiaryTypeLevel, setBeneficiaryTypeLevel] = useState("Beneficiary/Source Type");
    const [beneficiaryLevel, setBeneficiaryLevel] = useState("Beneficiary");
    const [form] = Form.useForm();
    const history = useHistory();
    const [po, setPO] = useState({});
    const [vendor, setVendor] = useState([]);
    const [loader, setLoader] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [voucherNo, setVoucherNo] = useState("");
    const [benificiariesData, setBenificiaryData] = useState([]);
    const [loadingstatus, setLoadingStatus] = useState({
        'transaction': false,
        'beneficiary': false,
        'details': false
    });

    const [transactionAccount, setTransactionAccount] = useState({
        'mainaccount': [],
        'accountbychild': [],
        'bankaccount': [],
        'cashaccount': [],
    });

    const [beneficiary, setBenificiary] = useState({
        'customer': [],
        'vendor': [],
        'employee': [],
        'hqdepartment': [],
        'area': [],
        'district': [],
        'local': [],
        'staff': [],
        'ministries': [],
        'pentsos': [],
        'committee': [],
        'subsectors': [],
    });

    const[polist, setPolist] = useState({
        voucher_type: "Payment",
        reference_no: CisUI().getToday(),
        benificiary_type: null,
        benifiicary_id: null,
        details: null,
        payment_method: null,
        account_id: null,
        sub_account_id: null,
        title: null,
        bankname_id: null,
        bank_account_id: null,
        amount: null,
        check_no: null,
        remark: null,
        check_status: false,
        files: []
    })

    const [filteredBankAcocunt, setFilteredBankAccount] = useState([]);
    const [currency, setCurrency] = useState("GHC");
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [voucherType, setVoucherType] = useState("Payment");
    const [amount, setAmount] = useState(0);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState([]);
    const [selectedSubAccount, setSelectedSubAccount] = useState([]);

    let Companies = [];

    const [files, setFiles] = useState([]);
    const [checkStatus, setCheckStatus] = useState(0);
    const [isCash, setIsCash] = useState(false);
    const [trxAccount, setTrxAccount] = useState([]);
    const [bankAccountBalance, setBankAccountBalance] = useState(0.00);

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
        if(e.target.value === "Payment"){
            const mainAccount = transactionAccount.mainaccount;
            const mFiltered = mainAccount.filter((item)=> (item.act_type === 12 || item.act_type === 2 || item.act_type === 7));
            setTrxAccount(mFiltered);
            setBeneficiaryTypeLevel("Beneficiary Type")
            setBeneficiaryLevel("Beneficiary")
        }
        else {
            const mainAccount = transactionAccount.mainaccount;
            const mFiltered = mainAccount.filter((item)=> item.act_type !== 12);
            setTrxAccount(mFiltered);
            setBeneficiaryTypeLevel("Source Type")
            setBeneficiaryLevel("Source")
        }
        form.setFieldsValue({
            account_id: undefined,
            sub_account_id: undefined
        })
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const onCheckChange = (e) => {
        console.log(e);
        if (e.target.checked === true) {
            setCheckStatus(1);
        } else {
            setCheckStatus(0);
        }
    }

    const BankName = SelectData("banknames");
    const PaymentMethod = SelectData("paymentmethod");
    const BenificiaryType = SelectData("accountbenificary");

    useEffect(() => {

    }, []);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    // let endpoint = "entries/singlevoucheredit";
    // let redirectto = "../" + jsonfile.urls.list;


    let endpoint = "entries/singlevoucheredit/"+ userID;
    let redirectto = "../../" + jsonfile.urls.list;

    let BenificaryData = [];

    const onBankChange = (value) => {
        // let vl = value + Math.floor(Math.random() * 900000);
        let vl = voucherNo;
        // let bankAccountD = transactionAccount.bankaccount.filter(item => item.prefix === value);
        // setFilteredBankAccount(bankAccountD);
        // form.setFieldsValue({reference_no: vl, bank_account_id: null});

        console.log("Bank Selected :", BankName);

        const bank = BankName.filter((item)=> item.bank_id === value);
        // console.log("Bank Selected :"+ value);
        let bank_id = 0;
        if(bank.length > 0) {
            vl = bank[0].id + vl;
            bank_id = bank[0].bank_id;
            console.log(bank);
            if(bank_id > 0) {
                let bankAccountD = transactionAccount.bankaccount.filter(item => item.bank_id === bank_id);
                console.log(transactionAccount)
                if(bankAccountD.length > 0) {
                    setFilteredBankAccount(bankAccountD);
                }
                else {
                    setFilteredBankAccount([]);
                }
            }
            else {
                setFilteredBankAccount([]);
            }
        }
        else {
            setFilteredBankAccount([]);
        }
        form.setFieldsValue({reference_no: vl, bank_account_id: null});

    }
    console.log("filter", filteredBankAcocunt)

    const onFinish = (values) => {
        if (checkStatus === 1) {
            values["checque_status"] = 1;
        } else {
            values["checque_status"] = 0;
        }
        values['fileItem'] = files;
        values['currency'] = currency;
        console.log(values);
        console.log("check", checkStatus);
        // return;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success", "success", res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    history.push(redirectto);
                } else {
                    CisUI().showAlert("Alert", "warning", res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            });
    };

    useEffect(() => {
        // getPODetails();
        getTransactionAccounts();
        getBeneficiaryData();

    }, []);

    useEffect(() => {
        if(transactionAccount?.mainaccount?.length > 0 || beneficiary?.customer?.length > 0) {
            getPODetails();
            // console.log("DDD");
        }
    }, [transactionAccount, beneficiary])

    const getTransactionAccounts = () => {
        // document.getElementById("loader").style.display = "block";
        setLoader(true)
        axios.get(Config.apiserver+"transactionaccount")
            .then((res) => {
                // document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res.data.data;
                    setTransactionAccount(data);
                    // setLoadingStatus({
                    //     ...loadingstatus,
                    //     transaction: true
                    // })
                }
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                // document.getElementById("loader").style.display = "none";
            });
    }

    const getBeneficiaryData = () => {
        // document.getElementById("loader").style.display = "block";
        setLoader(true)
        axios.get(Config.apiserver+"beneficiarylist")
            .then((res) => {
                // document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res.data.data;
                    setBenificiary(data);
                    // setLoadingStatus({
                    //     ...loadingstatus,
                    //     beneficiary: true
                    // })
                    // loadingstatus["beneficiary"] = true;
                }
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                // document.getElementById("loader").style.display = "none";
            });
    }


    const getPODetails = () => {
        // console.count('loader')
        // loadingstatus["details"] = true;
        setLoader(true)
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if (res.data.status === 1) {
                    const data = res?.data?.data;
                    console.log("DataObject : "+ data);

                    let vt = "Receipt";
                    let amount = (data?.debit).toFixed(2);
                    const mainAccount = transactionAccount?.mainaccount;
                    const mFiltered = mainAccount.filter((item)=> item.act_type !== 12);
                    setTrxAccount(mFiltered);
                    setBeneficiaryTypeLevel("Source Type")
                    setBeneficiaryLevel("Source")
                    if (data.credit > 0) {
                        vt = "Payment";
                        amount = (data.credit).toFixed(2);
                        const mainAccount = transactionAccount.mainaccount;
                        const mFiltered = mainAccount.filter((item)=> (item.act_type === 12 || item.act_type === 2 || item.act_type === 7));
                        setTrxAccount(mFiltered);
                        setBeneficiaryTypeLevel("Beneficiary Type")
                        setBeneficiaryLevel("Beneficiary")
                    }

                    setPO(res.data.data);

                    if(data.actd_type === 2) {
                        console.log("TT: "+data.actd_type);
                        onChangeAccount(data.account_id);
                    }

                    if(data?.bank_id > 0) {
                        let bank_id = data?.bank_id;

                        let bankAccountD = transactionAccount.bankaccount.filter(item => item.bank_id === bank_id);
                        if(bankAccountD.length > 0) {
                            setFilteredBankAccount(bankAccountD);
                        }
                        else {
                            setFilteredBankAccount([]);
                        }
                    }
                    else {
                        setFilteredBankAccount([]);
                    }
                    // onBankChange(data?.bank_account_id)
                    const dataObject = {
                        voucher_type: vt,
                        date: moment(data?.date, "YYYY-MM-DD"),
                        reference_no: data?.voucher_no,
                        benificiary_type: data?.benificiary_type,
                        benificiary_id: data?.benificiary_id,
                        details: data?.title,
                        payment_method: data?.type,
                        account_id: data?.account_id,
                        sub_account_id: data?.sub_account_id,
                        title: data?.details,
                        bank_id: data?.bank_id,
                        bank_account_id: data?.bank_account_id,
                        amount: amount,
                        check_no: data?.reference,
                        check_status: data?.checque_status,
                        notes: data?.notes,
                        files: data?.files
                    };
                    setAttachment(data?.files)
                    setCheckStatus(data?.checque_status);
                    setPolist(dataObject);
                    onBenificaryChange(data.benificiary_type);
                    onPaymentMethodChange(data.type);
                    let split = data.voucher_no.split("");
                    setVoucherNo(split.slice(2, (split.length - 1)).join(""));
                    // console.log("DataObject : ", dataObject);
                    getBalance(data?.bank_account_id)
                    form.setFieldsValue(dataObject);
                    setVoucherType(vt)


                    // onBenificaryChange(data.benificiary_type);
                    //setBenificiaryData(vendor);
                    // return true;
                }
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
                // loadingstatus["details"] = false;
            });

    }

    // if(loadingstatus.transaction && loadingstatus.beneficiary) {
    //    getPODetails();
    //     console.log("DDD");
    // }

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        let refer = voucherNo;
        if (value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        } else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        form.setFieldsValue({
            bank_account_id: undefined,
            bank_id: undefined,
            reference_no: refer
        });
    }


    const getCurrency = (value) => {
        axios.get(Config.apiserver+"getcurrencybyaccount/"+value)
            .then((res) => {
                if(res.data.status === 1) {
                    setCurrency(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        if (!isCash) {
            transactionAccount.bankaccount.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });
            getBalance(value)
            // console.log("RefNo : " + ref);

            form.setFieldsValue({
                check_no: ref,
            });

            getCurrency(value);
        }
    }
    
    const getBalance = (value) => {
        axios.get(Config.apiserver+"getbalancebybankaccount/"+value)
            .then((res) => {
                console.log(res.data.data)
                if(res.data.status === 1) {
                    setBankAccountBalance(res.data.data.total_balance);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const Local = SelectData("alllocal");

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = beneficiary.customer;
            setSelectedBeneficiary(beneficiary.customer);
        } else if (e === 3) {
            BenificaryData = beneficiary.vendor;
            setSelectedBeneficiary(beneficiary.vendor);
        } else if (e === 4) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 6) {
            BenificaryData = beneficiary.department;
            setSelectedBeneficiary(beneficiary.department);
        } else if (e === 7) {
            BenificaryData = beneficiary.area;
            setSelectedBeneficiary(beneficiary.area);
        } else if (e === 8) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 9) {
            BenificaryData = beneficiary.employee;
            setSelectedBeneficiary(beneficiary.employee);
        } else if (e === 10) {
            BenificaryData = beneficiary.ministries;
            setSelectedBeneficiary(beneficiary.ministries);
        } else if (e === 11) {
            BenificaryData = beneficiary.pentsos;
            setSelectedBeneficiary(beneficiary.pentsos);
        } else if (e === 12) {
            BenificaryData = beneficiary.district;
            setSelectedBeneficiary(beneficiary.district);
        } else if (e === 13) {
            BenificaryData = Local;
            setSelectedBeneficiary(Local);
        }
        else if (e === 14) {
            BenificaryData = beneficiary.committee;
            setSelectedBeneficiary(beneficiary.committee);
        }
        else if (e === 15) {
            BenificaryData = beneficiary.subsector;
            setSelectedBeneficiary(beneficiary.subsector);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }

    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpeg, jpg, png, docx, excel and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        let filedata = [...files];
                        filedata.push({
                            file_id : data.data.id,
                            file_location : data.data.location,
                        });
                        setFiles(filedata);
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    const onChangeAccount = (e) => {
        const sub = transactionAccount.accountbychild[parseInt(e)] || [];
        setSelectedSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }

    const onCompanyChange = () => {

    }

    const DeleteFile = (fileID) => {
        console.log("fileID", fileID)

        // return;
        axios.post(Config.apiserver+'filedelete', {'fileid': fileID, 'lineid': userID})
            .then(res => {
                if(res?.data?.status === 1){
                    // let po = polist;
                    // let file_filter = po.files;
                    let newFiles = attachment.filter((item, index) => item.id !== fileID);
                    console.log("file_filter", newFiles)
                    setPolist({
                        files: newFiles
                    })
                    CisUI().showAlert("Success", "success", res.data.msg);
                }
                else{
                    CisUI().showAlert("Error", "error", res.data.msg);
                }
            })
            .catch(error => console.log(error))
    }

    const noBillField = (
        <>

            <div className="row">
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="voucher_type"
                        label="Voucher Type"
                        rules={[
                            {
                                required: true,
                                message: "Select a voucher type"
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeRadio} value={voucherType}>
                            <Radio value={"Payment"}>Payment</Radio>
                            <Radio value={"Receipt"}
                                   style={voucherType === 'Receipt' ? {color: "green"} : {}}>Receipt</Radio>
                        </Radio.Group>

                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat}
                                    className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>
                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                        <div className="col-md-4 col-sm-6">
                            <Form.Item
                                name="com_id"
                                label="Company"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="reference_no"
                        label="Voucher No#"
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input readOnly={true} className="gx-mb-3 gx-w-100 readonly"
                               placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="benificiary_type"
                        label={beneficiaryTypeLevel}
                        rules={[
                            {
                                required: true,
                                message: "Select a Beneficiary/source"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an payment source"
                            optionFilterProp="children"
                            onChange={onBenificaryChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                BenificiaryType.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="benificiary_id"
                        label={beneficiaryLevel}
                        rules={[
                            {
                                required: true,
                                message: "Select a beneficiary"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an beneficiary"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                selectedBeneficiary.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="account_id"
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            onChange={onChangeAccount}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                // transactionAccount.mainaccount.map((items, index) =>
                                trxAccount.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                {
                    selectedSubAccount.length > 0 ?
                        <div className="col-md-4 col-sm-6">
                            <Form.Item
                                name="sub_account_id"
                                label="Sub Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        selectedSubAccount.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div> : ""
                }
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="title"
                        label="Description/Narration"
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        {/*<Input placeholder="Enter description here"/>*/}
                        <TextArea placeholder="Enter description here" maxLength={150} showCount/>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payment method"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                {
                    isCash === false &&
                    <div className="col-md-4 col-sm-6">
                        <Form.Item
                            name="bank_id"
                            label="Bank"
                            rules={[
                                {
                                    required: false,
                                    message: "Select a bank"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a bank"
                                optionFilterProp="children"
                                onChange={onBankChange}
                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                            >
                                {
                                    BankName.map((items, index) =>
                                        <Option key={++index} value={items.bank_id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                }

                <div className="col-md-4 col-sm-6" id="bankaccount">
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                isCash ?
                                    transactionAccount.cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    filteredBankAcocunt.map((items, index) =>
                                        <Option key={++index}
                                                value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                    {
                        !isCash && <p style={{ marginLeft: "14px", color: "#dd0000"}}>Total Balance Is: {CisUI().getCurrencyFormated1(bankAccountBalance)}</p>
                    }
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="amount"
                        label="Amount in GHC"
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        {/*<InputNumber*/}
                        {/*    style={voucherType === 'Payment' ? {color: "#545454", fontWeight: 'bold' ,width: '100%'} : {color: "green", fontWeight: 'bold', width: '100%'}}*/}
                        {/*    defaultValue={amount}*/}
                        {/*    formatter={value => `₵ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                        {/*    parser={value => value.replace(/\₵\s?|(,*)/g, '')}*/}
                        {/*    onChange={onAmountChange}*/}
                        {/*/>*/}
                        <InputNumber className="gx-mb-3 gx-w-100" min={0} style={voucherType === 'Payment' ? {color: "#545454"} : {color: "green"}}
                               placeholder="Enter amount here"/>
                    </Form.Item>
                </div>
                {
                    currency !== 'GHC' ?
                        <div className="col-md-4 col-sm-6">
                            <Form.Item
                                name="amount_fc"
                                label={"Amount in " + currency}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter amount in " + currency
                                    },
                                ]}
                            >
                                {/*<InputNumber*/}
                                {/*    style={{width: '100%'}}*/}
                                {/*    defaultValue={amount}*/}
                                {/*    formatter={value => `₵ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                                {/*    parser={value => value.replace(/\₵\s?|(,*)/g, '')}*/}
                                {/*    onChange={onAmountChange}*/}
                                {/*/>*/}
                                <Input placeholder={"Enter amount in " + currency}/>
                            </Form.Item>
                        </div> : <></>
                }
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="check_no"
                        label="Cheque No# / Reference No#"
                        rules={[
                            {
                                required: false,
                                message: "Enter Cheque no"
                            },
                        ]}
                    >
                        <Input placeholder="Enter Cheque/reference no"/>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="notes"
                        label="Remark/Memo"
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter something here"/>
                    </Form.Item>
                </div>
                <div className="col-md-4 col-sm-6">
                    <Form.Item
                        name="check_status"
                        label="Cheque Cleared?"
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <Checkbox checked={checkStatus} onChange={onCheckChange} value={checkStatus}/>
                    </Form.Item>
                </div>
                <div className="col-md-8 col-sm-8">
                    {/*<br/>*/}
                    <Form.Item
                        name="file"
                        label="Upload File (Supported File: jpg, pjeg, png, pdf, docx, excel)"
                    >

                        <Upload data={
                            {
                                file: formData,
                                type: "voucher",
                                file_id: fileUID
                            }
                        } {...uplod}>
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>

                        </Upload>
                        {
                            attachment?.length > 0 &&
                            <div>
                                {
                                    attachment?.map((item, index) =>
                                        <span style={{display: "flex"}}>
                                            <a href={`${Config.fileserver}${item?.file}`}
                                               target="_blank">{item?.file_name}</a>
                                            <button type='button' className="btn btn-danger btn-circle"
                                                    onClick={() => DeleteFile(item?.id)}>
                                                <i className="fas fa-trash"/>
                                            </button>
                                        </span>
                                    )
                                }

                            </div>
                        }
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button type="info" onClick={() => history.goBack()}>Cancel</Button>
                </div>
            </div>
        </>
    );

    // if(loader){
    //     return <CircularProgress />;
    // }

    return (
        <Card title={"Edit Voucher Entry"} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {
                // (transactionAccount?.mainaccount?.length > 0 && beneficiary?.customer?.length > 0) ?
                !loader ?
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        // ref={formRef}
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {CisUI().showLoading}
                        {
                            noBillField
                        }
                    </Form>
                     : <CircularProgress className={`form_custom_loader`}/>
             }
            
            
            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm}
                   onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject}
                   onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default SingleVoucherEditV2;