import {Form, Input, Button, Card, Select, DatePicker, Upload, message, InputNumber} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './pvoucher.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import {UploadOutlined} from "@ant-design/icons";
import CircularProgress from "../../../../components/CircularProgress";
const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const PVCashVoucherEdit = (props) => {

    const [form] = Form.useForm();
    const history = useHistory();

    const [doc, setDoc] = useState({});
    const [selectedSegment, setSelectedSegment] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(true);
    const [benificiariesData, setBenificiaryData] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [voucherType, setVoucherType] = useState("Payment");
    const [files, setFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [attachments, setAttachments] = useState([]);

    let id = props.match.params.id;
    const comID = CisUI().getUserInfo('com_id');

    useEffect(() => {
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    useEffect(() => {
        if(customer.length > 0 || employee.length > 0){
            getDetails()
        }
    }, [customer, employee])

    const getDetails = () => {
        // document.getElementById("loader").style.display = "block";
        setLoader(true)
        axios.get(Config.apiserver + jsonfile.urls.view + '/' + id)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res?.data?.data;
                    // console.log(data)
                    onBenificaryChange(data?.benificiary_type)
                    onPaymentMethodChange(data?.payment_method_id)
                    form.setFieldsValue({
                        ...data,
                        date: moment(data?.date, "YYYY-MM-DD"),
                        reference_no: data?.voucher_no,
                        title: data?.description,
                        check_no: data?.ref_no,
                        amount: data?.voucher_amount,
                        payment_method: data?.payment_method_id
                    })
                    setAttachments(data?.attachment)
                }
                setLoader(false)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const PaymentMethod = SelectData("paymentmethod");
    // const BankAccount = SelectData("bankaccount");
    const Benificiary = SelectData("accountbenificary?type=pvoucher");

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            form.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            setLoader(true)
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data.customer);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        const mAccount = res.data.main_account;
                        const mAccount1 = mAccount.filter((item)=> item.account_number === 4500);
                        let actID = 0;
                        if(mAccount1.length > 0) {
                            actID = mAccount1[0].id;
                        }
                        setMainAccount(mAccount1);
                        form.setFieldValue("account_id",actID);
                        const subAct = res.data.child_account;
                        const subAccount1 = subAct[actID] || []
                        let subActID = 0;
                        if(subAccount1.length > 0) {
                            const subFilter = subAccount1.filter((item) => item.account_number === 4540);
                            if(subFilter.length > 0) {
                                subActID = subFilter[0].id;
                                setChildAccount(subFilter);
                                setSubAccount(subFilter);
                            }
                        }

                        form.setFieldsValue({
                            sub_account_id: subActID
                        });
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    console.log(error);
                });

            form.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    let endpoint = jsonfile.urls.edit + '/' + id;
    let redirectto = "../../" + jsonfile.urls.list;

    const onFinish = (values) => {
        setLoader(true)

        // values["benificiary_type"] = 4;
        values["bank_id"] = 0;
        values["bankname_id"] = 0;
        values["voucher_type"] = "Payment";
        values["checque_status"] = 1;
        values['fileItem'] = files;
        // console.log(values);
        // return;
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    // document.getElementById("loader").style.display = "none";
                    history.push(redirectto);
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                    // document.getElementById("loader").style.display = "none";
                }
                setLoader(false);
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                // setLoading({loading: false});
                // document.getElementById("loader").style.display = "none";
            });
    };


    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 1) {
            setIsCash(true);
        }
        else {
            setIsCash(false);
        }
        form.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }
    let BenificaryData = [];

    const onBankAccountChange = (value) => {
        let ref = "";
        if(!isCash) {
            bankaccounts.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });

            form.setFieldsValue({
                check_no: ref,
            });
        }
    }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ",e);
        if(e === 2) {
            console.log("customer", customer)
            BenificaryData = customer;
            setBenificiaryData(customer);
        }
        else if(e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        }
        else if(e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        }
        else if(e === 8) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        }

        form.setFieldsValue({
            benificiary_id : null
        });

    }


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        let filedata = [...files];
                        filedata.push({
                            file_id : data.data.id,
                            file_location : data.data.location,
                        });
                        setFiles(filedata);
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }


    const fields = (
        <>
            <div className="row">

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                        <div className="col-md-4">
                            <Form.Item
                                name="com_id"
                                label="Department/Branch"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a Department/Branch"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a Department/Branch"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="Voucher No#"
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input readOnly={true} className="gx-mb-3 gx-w-100 readonly" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="benificiary_type"
                        label="Beneficiary/Source Type"
                        rules={[
                            {
                                required: true,
                                message: "Select a Beneficiary/source"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an payment source"
                            optionFilterProp="children"
                            onChange={onBenificaryChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                Benificiary.map((items, index) =>
                                 // if(items.id === 4) {
                                     (items.id === 2 || items.id === 8) && <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="benificiary_id"
                        label="Beneficiary"
                        rules={[
                            {
                                required: true,
                                message: "Select a beneficiary"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an beneficiary"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                benificiariesData.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="account_id"
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            onChange={onChangeAccount}
                            allowClear={false}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                mainaccount.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                {
                    subaccount.length > 0 ?
                        <div className="col-md-4">
                            <Form.Item
                                name="sub_account_id"
                                label="Sub Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="children"
                                    allowClear={false}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        subaccount.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div> : ""
                }

                <div className="col-md-4">
                    <Form.Item
                        name="title"
                        label="Description/Narration"
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter description here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        initialValue={1}
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4" id="bankaccount" >
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                isCash ?
                                    cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="check_no"
                        label="Check/Reference No#"
                        rules={[
                            {
                                required: false,
                                message: "Enter check no"
                            },
                        ]}
                    >
                        <Input placeholder="Enter check/reference no"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        <InputNumber className="gx-mb-3 gx-w-100" min={0} placeholder="Enter amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        name="remark"
                        label="Remark/Payee"
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea rows={5} style={{ height : '120px' }} maxLength={200} placeholder="Enter something here"/>
                    </Form.Item>
                </div>

                <div className="col-md-6">
                    <Form.Item
                        name="file"
                        label="Upload File (Supported File: jpg, pjeg, png, pdf, docx, excel)"
                    >
                        <Upload data= {
                            {
                                file : formData,
                                type : "pvcashvoucher",
                                file_id : fileUID
                            }
                        } {...uplod}>
                            <Button icon={<UploadOutlined />}> Click to Upload</Button>
                        </Upload>
                        <div>
                        {
                            attachments.length > 0 && attachments.map((item, index) =>
                                <span style={{display: "flex"}}>
                                    <a href={`${Config.fileserver}${item?.file}`}
                                       target="_blank">{item?.file_name}</a>
                                    <button type='button' className="btn btn-danger btn-circle"
                                            // onClick={() => DeleteFile(item?.id)}
                                    >
                                        <i className="fas fa-trash"/>
                                    </button>
                                </span>
                            )
                        }
                    </div>
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <a className="ant-btn ant-alert-info" onClick={() => history.goBack()}>Cancel</a>
                </div>
            </div>
        </>
    );

    if (loader) {
        return <CircularProgress/>
    }

    return (
        <Card title={"Edit Provisional Cash Voucher"} extra={CisUI().addAction('../../' + jsonfile.urls.list)}>
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                // ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {CisUI().showLoading}

                {
                    fields
                }
            </Form>
        </Card>
    );
};

export default PVCashVoucherEdit;