import React, {useState, useEffect} from "react";
import {Form, Row, Col, Input, Button, Popconfirm, notification, Collapse, Card, Table} from 'antd';
import jsonfile from './localexpenses.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import SelectData from "../../../../util/selectdata";
import axios from '../../../../util/Api';
import jsPDF from 'jspdf';
import moment from 'moment';

const Search = Input.Search;
const Panel = Collapse.Panel;

const RegionMonthlyReport = () => {
    const value = 0;
    const ref = React.createRef();
    const formRef = React.createRef();

    const [monthStart,setMonthStart] = useState("");
    const [reportClosed,setReportClosed] = useState(false);
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.monthly,
        filtervalue: '',
        reportTitle: '',
        reportData: [],
        totalContribution: 0,
        month: "",
        region: "",
        district: "",
        districts: [],
        districtData: [],
        contribution: [],
        expensesOffice: [],
        expensesMission: [],
        totalExpenses: 0,
        netTitheAfterExpense: 0,
        rdf: 0,
        retainRDF: 0,
        aidf: 0,
        NetTitheToNation: 0,
        missionOffering: 0,
        MinistryIncome: 0,
        Mckeowns: 0,
        InternalMissions: 0,
        NationalPrayer: 0,
        PENSOS: 0,
        netCashToNation: 0,
        region_id : 0,
        reportClosed : 0,
    });

    const [report, setReport] = useState({});

    const history = useHistory();


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    const mnts = CisUI().ParseDateFormatFromMonth(wk);
                    //console.log(mnts);
                    wkd = mnts;
                    return `${key}=${mnts}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.monthly + "?" + qsup;
        console.log("NEURL :" + newURL);
        console.log("Month : " + wkd);
        const dts = values['month'];
        //console.log("Dt : "+dts);
        //console.log("Selected Date : "+ moment(dts).format("MMMM YYYY"));
        const WKR = CisUI().getMonthNameTimeStamp(dts);
        const reportTitle = "Monthly Report For the month of "+WKR;
        setMonthStart(moment(dts).format("YYYY-MM")+"-01");

        const url = Config.apiserver + jsonfile.urls.monthly;
        //const data = SelectData(url);

        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data.data;
                    setState({
                        reqURL: newURL,
                        contribution: data.contribution,
                        totalContribution: data.totalContribution,
                        districts: data.districts,
                        districtData: data.districtData,
                        district: data.district,
                        region: data.region,
                        reportTitle: reportTitle,
                        month: CisUI().getMonthTitle(wkd),
                        expenses: data.expenses,
                        expensesOffice: data.expensesOffice,
                        expensesMission: data.expensesMission,
                        totalExpensesOffice: data.totalExpensesOffice,
                        totalExpensesMission: data.totalExpensesMission,
                        totalExpenses: data.totalExpenses,
                        netTitheAfterExpense: data.netTitheAfterExpense,
                        rdf: data.rdf,
                        retainRDF: data.retainRDF,
                        aidf: data.aidf,
                        NetTitheToNation: data.NetTitheToNation,
                        missionOffering: data.missionOffering,
                        MinistryIncome: data.MinistryIncome,
                        Mckeowns: data.Mckeowns,
                        InternalMissions: data.InternalMissions,
                        NationalPrayer: data.NationalPrayer,
                        PENSOS: data.PENSOS,
                        netCashToNation: data.netCashToNation,
                        region_id : data.region_id,
                        reportClosed : data.reportClosed,
                    });

                    if(data.reportClosed === 1) {
                        setReportClosed(true);
                    }
                    else {
                        setReportClosed(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input, "", 0, 'month')}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        // window.print();
        window.open(Config.baseurl+"monthlyreportprint.html?url="+window.btoa(state.reqURL)+"&filename=region_monthly_report&data=regionmonthlyData&subtitle="+state.reportTitle+"&rptype=regionmonthlyreport","_blank");
    }


    const exportToPDF = () => {

        var doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: "landscape",
            unit: "px"
        });

        // var doc = new jsPDF('l', 'px', 'letter');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const marginX = (pageWidth - 80) / 2;
        const xOffset = (doc.internal.pageSize.width / 2);
        const user = localStorage.getItem('userData');
        const uj = JSON.parse(user)

        doc.addImage(Config.print_logourl, "PNG", marginX, 5, 80, 27);
        doc.text("Area Summary Report",xOffset,37, { align: 'center' });
        doc.setFontSize(12);
        doc.text(state.reportTitle,xOffset,43, { align: 'center' });
        doc.setFontSize(8);
        doc.text("Printed By : "+uj["name"],20,50, { align: 'left' });
        doc.setFontSize(8);
        doc.text("Date/Time : "+moment().format("MM/DD/YYYY HH:mm"),20,55, { align: 'left' });

        var content = document.getElementById("region_summary").innerHTML;

        doc.html(content,
            {
                margin: [40, 60, 40, 60],
                callback: function (doc) {
                    doc.save("region_summary");
                },
                x: 20,
                y: 50
            })

    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height : '40px', display : 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height : '40px'}} alt="loader" /> Please wait ...
                </a>
                {/*<Link to={"../"+jsonfile.urls.add} className="btn btn-text-adjust btn-circle-md" title="Add New">*/}
                {/*    <i className="fas fa-plus"/>*/}
                {/*</Link>*/}
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );


    const ReportData = state.reportData;
    const expenseTypes = SelectData("donation/expensetype?area=6");

    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };

    const MonthClose = () => {
        console.log("Month : "+monthStart);
        if(monthStart === "" || state.region_id === 0) {
            notification.warning({
                message: 'Alert',
                type : "error",
                description: "Month should be selected and report should be loaded"
            });
        }
        else {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + jsonfile.urls.MonthClose, {"month": monthStart, "region_id": state.region_id})
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    if (res.data.status === 1) {
                        notification.warning({
                            message: 'Alert',
                            type : "success",
                            description: res.data.msg
                        });
                        setReportClosed(true);
                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type : "error",
                            description: res.data.msg
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }

    }

    return (
        <>
            {searchView}
            <Card id="reportview" extra={getExtra}>
                {CisUI().showLoading}
                <div ref={ref}>
                    <div className="row">
                        <div className="col-md-12 text-right" style={{textAlign: 'right'}}>
                            {/*{*/}
                            {/*    state.region_id > 0 ?*/}
                            {/*        reportClosed === false ?*/}
                            {/*            <Popconfirm title="By submitting you agree that your monthly returns will be closed and entry will be disabled for this month." onConfirm={MonthClose} okText="Confirm"*/}
                            {/*                        cancelText="Cancel">*/}
                            {/*                <button className="ant-btn ant-btn-primary">*/}
                            {/*                    <i className="fas fa-unlock"/> Submit*/}
                            {/*                </button>*/}
                            {/*            </Popconfirm>*/}
                            {/*            :<button className="ant-btn ant-btn-primary" type="button"><i className="fa fa-lock"></i> Month Closed</button>*/}
                            
                            {/*        : ""*/}
                            {/*}*/}
                        </div>
                        <div className="col-md-12 text-center">
                            <h3>Monthly Area Report</h3>
                            <h2>{state.reportTitle}</h2>
                        </div>
                        <div className="col-md-6">
                            <strong>Month :</strong> {state.month}
                            <div><strong>Area :</strong> {state.region}</div>
                            <div><strong>NO. OF DISTRICTS :</strong> {state.districtData.length}</div>
                        </div>
                        <div className="col-md-6 text-right">
                        </div>
                    </div>
                    <h4 style={{marginTop: '20px'}}>District Income & Expenses</h4>
                    <div className="table-responsive">
                    <table className="report2 table table-bordered">
                        <thead>
                        <tr>
                            <th>Title</th>
                            {
                                state.districts.map((items, index) =>
                                    <th style={{textAlign: 'right'}}>{items.name}</th>
                                )
                            }
                            <th style={{textAlign: 'right'}}>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.districtData.map((items, index) => {

                                    if (items.type === 'total') {
                                        return <tr>
                                            <td style={{fontWeight: 'bold'}}>{items.title}</td>
                                            {
                                                state.districts.map((items1, index1) =>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>{CisUI().getCurrencyFormated1(items.districtData[index1])}</td>
                                                )
                                            }
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>{CisUI().getCurrencyFormated1(items.total)}</td>
                                        </tr>
                                    } else if (items.type === 'minusBold') {
                                        return <tr>
                                            <td style={{fontWeight: 'bold'}}>{items.title}</td>
                                            {
                                                state.districts.map((items1, index1) =>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        fontWeight: 'bold'
                                                    }}>({CisUI().getCurrencyFormated1(items.districtData[index1])})</td>
                                                )
                                            }
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'bold'
                                            }}>({CisUI().getCurrencyFormated1(items.total)})
                                            </td>
                                        </tr>
                                    } else if (items.type === 'minus') {
                                        return <tr>
                                            <td>{items.title}</td>
                                            {
                                                state.districts.map((items1, index1) =>
                                                    <td style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(items.districtData[index1])})</td>
                                                )
                                            }
                                            <td style={{textAlign: 'right'}}>({CisUI().getCurrencyFormated1(items.total)})</td>
                                        </tr>
                                    } else {
                                        return <tr>
                                            <td>{items.title}</td>
                                            {
                                                state.districts.map((items1, index1) =>
                                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.districtData[index1])}</td>
                                                )
                                            }
                                            <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.total)}</td>
                                        </tr>
                                    }

                                }
                            )
                        }
                        </tbody>
                    </table>
                    </div>

                    <h4>Area Income & Expenses</h4>
                    <table className="report table table-bordered">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>TOTAL CONTRIBUTION RECEIVED (Tithe & Offering)</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.totalContribution)}</th>
                        </tr>

                        <tr>
                            <th>AREA EXPENSES</th>
                            <th style={{textAlign: 'right'}}></th>
                            <th style={{textAlign: 'right'}}></th>
                        </tr>
                        {
                            state.expensesOffice.map((items, index) => {
                                    return <tr>
                                        <td>{items.title}</td>
                                        <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(items.amount)}</td>
                                        <td style={{textAlign: 'right'}}></td>
                                    </tr>
                                }
                            )
                        }
                        <tr>
                            <th>Total Expenses</th>
                            <th style={{textAlign : 'right'}}></th>
                            <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.totalExpenses)})</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe After AREA Expenses</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netTitheAfterExpense)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Less: AREA Development Fund (ADF : 10%)</th>
                            <th style={{textAlign : 'right'}}>({CisUI().getCurrencyFormated1(state.rdf)})</th>
                        </tr>
                        {/*<tr>*/}
                        {/*    <th colSpan={2} style={{textAlign : 'right'}}></th>*/}
                        {/*    <td style={{textAlign : 'right'}}></td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <th colSpan={2} style={{textAlign : 'right'}}>Distribution of 10% RDF</th>*/}
                        {/*    <td style={{textAlign : 'right'}}></td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td colSpan={2} style={{textAlign : 'right'}}>Retained 70% of RDF</td>*/}
                        {/*    <td style={{textAlign : 'left'}}>{CisUI().getCurrencyFormated1(state.retainRDF)}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td colSpan={2} style={{textAlign : 'right'}}>Remit 30% of RDF As AIDF</td>*/}
                        {/*    <td style={{textAlign : 'left'}}>{CisUI().getCurrencyFormated1(state.aidf)}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <th colSpan={2} style={{textAlign : 'right'}}></th>*/}
                        {/*    <td style={{textAlign : 'right'}}></td>*/}
                        {/*</tr>*/}
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Net Tithe to HQ</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NetTitheToNation)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Missions Offering</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.missionOffering)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign : 'right'}}><a href="#">Add : Ministry Income</a></th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.MinistryIncome)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Mckeowns</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.Mckeowns)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : Internal Missions</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.InternalMissions)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : National Prayer</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.NationalPrayer)}</th>
                        </tr>
                        <tr style={{display: 'none'}}>
                            <th colSpan={2} style={{textAlign : 'right'}}>Add : PENSOS</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.PENSOS)}</th>
                        </tr>
                        <tr>
                            <th colSpan={2} style={{textAlign : 'right'}}>AMOUNT SENT TO HQ	</th>
                            <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(state.netCashToNation)}</th>
                        </tr>

                        </tbody>
                    </table>
                </div>

            </Card>
        </>
    );
};

export default RegionMonthlyReport;